@use "@angular/material" as mat;
@use "../m3-theme";

@include mat.core();

$buzzwave-admin-light-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      density: (scale: 0,
      ),
    ));

$buzzwave-admin-dark-theme: mat.define-theme((color: (theme-type: dark,
        primary: mat.$magenta-palette,
        tertiary: mat.$violet-palette,
      ),
      density: (scale: 0,
      ),
    ));

// Light theme
// Un comment below to use theme created by ng add @angular-material
$theme: $buzzwave-admin-light-theme;

// Light Theme created by ng generate @angular-material:m3-theme
$theme: m3-theme.$light-theme;

// Dark theme
// Un-comment below to use dark theme using @angular/material
// $dark-theme: $buzzwave-admin-dark-theme;

// Dark Theme created by ng generate @angular-material:m3-theme
$dark-theme: m3-theme.$dark-theme;

:root {
  @include mat.all-component-themes($theme);

  // Add backwards compatibility styles for color variants
  @include mat.color-variants-backwards-compatibility($theme);

  @include mat.progress-bar-overrides((
    active-indicator-color: #e29b3e
  ));

  // The prefers-color-scheme CSS media feature is used to detect if a user has requested light or dark color themes. A user indicates their preference through an operating system setting (e.g. light or dark mode) or a user agent setting.
  // @media (prefers-color-scheme: dark) {
  //   @include mat.all-component-colors($dark-theme);

  //   // Components themes
  //   @include layout-component.theme($dark-theme);

  //   @include mat.color-variants-backwards-compatibility($dark-theme);
  // }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3 {
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
}

// Custom colours
$dark-blue: #003366;
$dark-blue-hover: #023e7a;
$mid-blue: #006AFF;
$light-blue: #E5F0FF;

$mid-grey: #808097;
$light-grey: #F9F9FF;
$light-grey-2: #F6F6F6;

$error-red: #de3730;

// used in pills
$active-blue: #CCE1FF;

$completed-green: #188038;
$completed-green-2: #e6f4ea;

$pill-background-grey: #F5F5F5;
$pill-foreground-grey: #808080;
$pill-background-yellow: #FFF5CC;
$pill-background-blue: #E9F2FF;
$pill-background-green: #D9F2DD;
$pill-background-red: #FFF4F6;
$pill-foreground-red: #F55757;


// Custom classes

body {
  background-color: #f2f2f2;
}

.flex {
  display: flex;
  flex-flow: row wrap;
}

.flex-66 {
  width: 60%;
}

.flex-30 {
  width: 40%;
}

.flex-50 {
  width: 50%;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.space-between {
  justify-content: space-between;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-0 {
  margin-top: 0;
}

.popup-container {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
}

.progress-bar {
  width: 120px !important;
  margin-top: 20px !important;
}

.preline {
  white-space: pre-line;
}

.centered-popup-container {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  height: 100vh;
  margin: 0;
  flex-direction: column;
}

.content-container {
  text-align: center;
  background-color: #fff;
  margin: 10px 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.logo {
  max-width: 100%;
  max-height: 130px;
  padding-top: 24px;
  padding-bottom: 12px;
}

.italics {
  font-style: italic;
}

.link {
  color: blue;
  :visited {
    color: blue;
  }
}

.centered-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 15px 20px;
  background-color: #4e72b0;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.button-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.button-text {
  font-size: 18px;
}
